
















// libraries
import { Component, Vue } from 'vue-property-decorator';
import ScrollMagic from 'scrollmagic';

// utils
import { executeAfterTransition } from '@/utils/general';

// types
import { Routes } from '@/router/routes';

@Component
export default class MainLayout extends Vue {
  routes = [Routes.Home, Routes.Services, Routes.Credentials, Routes.Team, Routes.Career, Routes.Contact];

  controller: any = null;

  mounted() {
    this.init();

    window.addEventListener('resize', this.init);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.init);
  }

  init() {
    if (this.controller) {
      this.controller.destroy();
      this.controller = null;
    }

    this.controller = new ScrollMagic.Controller();

    if (this.$breakpoint.xlAndUp) {
      new ScrollMagic.Scene({ triggerElement: '#app', triggerHook: 0, duration: 150 })
        .setClassToggle((this.$refs.appHeading as Vue).$el, 'active')
        .addTo(this.controller);
    } else {
      (this.$refs.appHeading as Vue).$el.classList.add('active');
    }
  }

  updateAppHeading() {
    const appHeading = (this.$refs.appHeading as Vue).$el as HTMLElement;

    appHeading.style.transition = 'none';
    appHeading.classList.remove('fade', 'fade-in--right', 'active');
    appHeading.classList.add('fade', 'fade-in--right');

    const complete = () => {
      appHeading.style.transition = '';
      appHeading.classList.add('active');
    };

    executeAfterTransition(complete, appHeading, true);
  }

  updated() {
    this.updateAppHeading();
  }
}
